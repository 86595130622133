<template>
  <div>
    <div class="travel-shell"
         v-if="callbackId!=null">
      <div class="travel-title">
        旅行团接待登记
      </div>

      <van-form @submit="onSubmit"
                label-width="8em">
        <van-field required
                   disabled
                   :error="false"
                   v-model="info.travelRoute"
                   name="游览路线"
                   label="游览路线"
                   placeholder="请输入游览地"
                   :rules="[{ required: true, message: '请填写游览地' }]" />
        <!-- <van-field
        v-model="password"
        name="选择时间"
        label="选择时间"
        placeholder="请输入游览时间"
        :rules="[{ required: true, message: '请填写游览时间' }]"
      /> -->
        <van-field required
                   :error="false"
                   readonly
                   disabled
                   clickable
                   name="datetimePicker"
                   :value="info.travelTime"
                   label="选择时间"
                   placeholder="请输入游览时间"
                   @click="showDatePicker = true"
                   :rules="[{ required: true, message: '请选择时间' }]" />
        <van-popup v-model="showDatePicker"
                   position="bottom">
          <van-datetime-picker type="datetime"
                               @confirm="onDateConfirm"
                               @cancel="showDatePicker = false" />
        </van-popup>
        <van-field required
                   :error="false"
                   readonly
                   disabled
                   clickable
                   name="picker"
                   :value="info.team"
                   label="团队性质"
                   placeholder="请选择"
                   @click="showTeamPicker = true"
                   :rules="[{ required: true, message: '请选择' }]" />
        <van-popup v-model="showTeamPicker"
                   position="bottom">
          <van-picker show-toolbar
                      :columns="columns"
                      @confirm="onConfirm"
                      @cancel="showTeamPicker = false" />
        </van-popup>
        <van-field :error="false"
                   required
                   disabled
                   v-model="info.hotelId"
                   name="入住酒店"
                   label="入住酒店"
                   placeholder="请输入入住酒店"
                   :rules="[{ required: true, message: '请填写入住酒店' }]" />

        <van-field :error="false"
                   readonly
                   disabled
                   required
                   clickable
                   name="datetimePicker"
                   :value="info.enterTime"
                   label="入住时间"
                   placeholder="请输入入住时间"
                   @click="showDatePicker2 = true"
                   :rules="[{ required: true, message: '请选择时间' }]" />
        <van-popup v-model="showDatePicker2"
                   position="bottom">
          <van-datetime-picker type="datetime"
                               @confirm="onDateConfirm2"
                               @cancel="showDatePicker2 = false" />
        </van-popup>

        <van-field required
                   disabled
                   :error="false"
                   v-model="info.directorName"
                   name="负责人姓名"
                   label="负责人姓名"
                   placeholder="请输入姓名"
                   :rules="[{ required: true, message: '请填写姓名' }]" />

        <van-field required
                   disabled
                   :error="false"
                   v-model="info.directorPhone"
                   name="负责人联系方式"
                   label="负责人联系方式"
                   maxlength="11"
                   placeholder="请输入联系方式"
                   :rules="[{ required: true, message: '请填写联系方式' }]" />
        <van-field required
                   disabled
                   :error="false"
                   v-model="info.visitorsCount"
                   name="游客数量"
                   label="游客数量"
                   placeholder="请输入人数"
                   :rules="[{ required: true, message: '请填写人数' }]" />

        <!-- <div style="margin: 40px 60px 0">
          <van-button round
                      block
                      type="info"
                      native-type="submit">提交</van-button>
        </div> -->
      </van-form>
    </div>
    <div class="travel-shell"
         v-else>
      <div class="travel-title">
        旅行团接待登记
      </div>

      <van-form @submit="onSubmit"
                label-width="8em">
        <van-field required
                   :error="false"
                   v-model="form.travelRoute"
                   name="游览路线"
                   label="游览路线"
                   placeholder="请输入游览地"
                   :rules="[{ required: true, message: '请填写游览地' }]" />
        <!-- <van-field
        v-model="password"
        name="选择时间"
        label="选择时间"
        placeholder="请输入游览时间"
        :rules="[{ required: true, message: '请填写游览时间' }]"
      /> -->
        <van-field required
                   :error="false"
                   readonly
                   clickable
                   name="datetimePicker"
                   :value="form.travelTime"
                   label="选择时间"
                   placeholder="请输入游览时间"
                   @click="showDatePicker = true"
                   :rules="[{ required: true, message: '请选择时间' }]" />
        <van-popup v-model="showDatePicker"
                   position="bottom">
          <van-datetime-picker type="datetime"
                               @confirm="onDateConfirm"
                               @cancel="showDatePicker = false" />
        </van-popup>
        <van-field required
                   :error="false"
                   readonly
                   clickable
                   name="picker"
                   :value="teamNature"
                   label="团队性质"
                   placeholder="请选择"
                   @click="showTeamPicker = true"
                   :rules="[{ required: true, message: '请选择' }]" />
        <van-popup v-model="showTeamPicker"
                   position="bottom">
          <van-picker show-toolbar
                      :columns="columns"
                      @confirm="onConfirm"
                      @cancel="showTeamPicker = false" />
        </van-popup>
        <van-field :error="false"
                   required
                   v-model="form.hotelId"
                   name="入住酒店"
                   label="入住酒店"
                   placeholder="请输入入住酒店"
                   :rules="[{ required: true, message: '请填写入住酒店' }]" />

        <van-field :error="false"
                   readonly
                   required
                   clickable
                   name="datetimePicker"
                   :value="form.enterTime"
                   label="入住时间"
                   placeholder="请输入入住时间"
                   @click="showDatePicker2 = true"
                   :rules="[{ required: true, message: '请选择时间' }]" />
        <van-popup v-model="showDatePicker2"
                   position="bottom">
          <van-datetime-picker type="datetime"
                               @confirm="onDateConfirm2"
                               @cancel="showDatePicker2 = false" />
        </van-popup>

        <van-field required
                   :error="false"
                   v-model="form.directorName"
                   name="负责人姓名"
                   label="负责人姓名"
                   placeholder="请输入姓名"
                   :rules="[{ required: true, message: '请填写姓名' }]" />

        <van-field required
                   :error="false"
                   v-model="form.directorPhone"
                   name="负责人联系方式"
                   label="负责人联系方式"
                   maxlength="11"
                   type="digit"
                   placeholder="请输入联系方式"
                   :rules="[{ required: true, message: '请填写联系方式' }]" />
        <van-field required
                   :error="false"
                   v-model="form.visitorsCount"
                   name="游客数量"
                   label="游客数量"
                   type="digit"
                   placeholder="请输入人数"
                   :rules="[{ required: true, message: '请填写人数' }]" />

        <div style="margin: 40px 60px 0">
          <van-button round
                      block
                      type="info"
                      native-type="submit">提交</van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      // 表单
      form: {
        // 路线
        travelRoute: "",
        //   游览时间
        travelTime: "",
        //   团队性质 字典值
        teamNature: "",
        //   入住酒店
        hotelId: "",
        //   入住时间
        enterTime: "",
        //   负责人姓名
        directorName: "",
        // 负责人联系方式
        directorPhone: "",
        // 游客数量
        visitorsCount: null,
      },
      //   团队性质 显示label
      teamNature: "",
      //   团队下拉选
      selectList: [],
      columns: ["散客", "拼团"],
      showTeamPicker: false,
      showDatePicker: false,
      showDatePicker2: false,
      info: '',
      callbackId: null
    };
  },
  created () {
    this.info = JSON.parse(this.$route.params.info)
    this.callbackId = this.info.id
    // console.log(this.$route.params.info)
    //console.log(this.info, this.callbackId);
  },
  methods: {
    getSelectList () {
      this.$axios2
        .get(`/travelservice/zhTravelservice/getDictList`)
        .then((res) => {
          if (res.data.code === 200)
          {
            this.selectList = res.data.result;
            // console.log(this.selectList, '111111111111111111111')
            if (this.callbackId !== null)
            {
              this.selectList.forEach(item => {
                if (item.value === this.info.teamNature)
                {
                  this.info.team = item.label
                }
              })
            }
            this.columns = res.data.result.map((item) => {
              return item.label;
            });
            //console.log(res.data.result);
          }
        });
    },

    onSubmit (values) {
      const { data: res } = this.$axios2.post(
        `/travelservice/zhTravelservice/add`,
        this.form
      ).then(info => {
        // console.log(info, '45465456464654')
        this.$router.push({ name: 'code', params: { id: `${info.data.result.id}`, classify: `1` } })
      })
      console.log("res", res);
      console.log("submit", values);
    },
    onConfirm (value) {
      this.selectList.map((item) => {
        if (item.label === value)
        {
          this.form.teamNature = item.value;
        }
      });

      this.teamNature = value;
      this.showTeamPicker = false;
    },
    onDateConfirm (value) {
      var date = new Date(+value + 8 * 3600 * 1000)
        .toISOString()
        .replace(/T/g, " ")
        .replace(/\.[\d]{3}Z/, "");
      this.form.travelTime = date;
      this.showDatePicker = false;
    },
    onDateConfirm2 (value) {
      var date = new Date(+value + 8 * 3600 * 1000)
        .toISOString()
        .replace(/T/g, " ")
        .replace(/\.[\d]{3}Z/, "");
      this.form.enterTime = date;
      this.showDatePicker2 = false;
    },
  },
  mounted () {
    this.getSelectList();
    window.fullPathForm = window.location.href
    console.log(window.fullPathForm)
  },
};
</script>

<style lang="less" scoped>
.travel-shell {
  padding: 20px 20px;
  .travel-title {
    font-size: 18px;
    text-align: center;
    /* font-weight: bold; */
    margin: 0 0 30px;
  }
  .van-button {
    height: 40px;
    font-size: 15px;
  }
  /deep/.van-cell {
    padding: 12px 18px;
    background: none;
    flex-direction: column;
    &::after {
      border-bottom: 1px solid #bdbfc3;
    }
    .van-field__value {
      input {
        border-radius: 4px;
        height: 30px;
        padding: 0 8px;
        background-color: #fff;
        -webkit-user-select: text;
      }
      .van-field__body {
        padding: 0 8px;
        background-color: #fff;
        border-radius: 4px;
        .van-radio-group {
          width: 100%;
          flex-direction: column;
          margin: 8px 0;
          .van-radio--horizontal {
            margin-right: 0;
            &:not(:first-child) {
              border-top: 1px solid #bdbfc3;
              margin-top: 6px;
              padding-top: 6px;
            }
          }
        }
      }
    }
    .van-field__label {
      white-space: nowrap;
    }
  }
}
</style>
